export default {
    SCOPE: 'https://www.googleapis.com/auth/drive',
    RESPONSE_TYPE: 'code',
    ACCESS_TYPE: 'offline',
    REDIRECT_URI: 'https://warroom-miniapp-note-frontend.pages.dev/auth',
    CLIENT_ID: '628499684372-mapu5klhk62lp20dusv9a6vk71q4rv1p.apps.googleusercontent.com',
    CLIENT_SECRET: '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',
    GRANT_TYPE: 'authorization_code',
    FOLDER_ID: '1sI-HzDoiY-dq_qRHv1FsLvYBPFdiIdYa'
}
